html,
body {
  background-color: #fff !important;
  font-family: 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
}

a {
  text-decoration: none;
}

.smcx-embed {
  border: none !important;
  max-width: none !important;
  height: 100% !important;
  .smcx-iframe-container {
    max-width: none !important;
    height: 100% !important;
  }
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.slick-dots li button:before {
  font-size: 9px;
}

.slick-dots li.slick-active button:before {
  color: #264cca !important;
  opacity: 1 !important;
}
